/*global console, define, _ */

define(["registry", "Backbone", "workers/apirequest", "views/terms"], function (registry, Backbone, ApiRequestWorker, termsView) {

    var UserWorker= function () {
        this.initialize.apply(this, arguments);
    };

    _.extend(UserWorker.prototype, Backbone.Events, {

        initialize: function () {
            return true;
        },

        authenticate: function (email, password, silent, remember) {
            var self = this,
                req = null,
                queryString = null,
                params = null,
                pid = null,
                data = {
                    email: email,
                    pass: password
                };

            if (typeof remember != 'undefined' && remember == true) {
                data.remember = true;
            }

            // Check to see if a pid is specified
            queryString = window.location.href.split("?");
            if (queryString[1] !== undefined) {
                queryString = queryString[1];

                params = queryString.split("&");

                _.each(params, function (param) {
                    if (param.substr(0,4) === "pid=") {
                        pid = param.split("=")[1];
                        data.pid = pid;
                    }
                });
            }

            req = $.ajax({
                url: API_URL+ "auth.php",
                type: "POST",
                crossDomain: true,
                data: data
            }).done(function (body) {
                if (body.name !== undefined && body.api_key !== undefined) {
                    registry.user.token = body.api_key;
                    registry.user.email = email;

                    localStorage.apiToken = body.token;
                    if (typeof remember != 'undefined' && remember == true) {
                        localStorage.remember = true;
                    }


                    if (silent === undefined || silent === false) {

                        if (parseInt(body.tos_accepted, 10) === 0) {

                            termsView.show();
                            termsView.on("accepted", function () {
                                self.query();
                            });

                        } else {
                            self.query();
                        }

                    }
                }
            }).fail(function (err) {
                console.error(err);
            });

            return req;
        },

        query: function (token) {
            var self = this,
                req = new ApiRequestWorker({token: token});

            $.when(
                req.request("data/partner.php"),
                req.request("data/partner-ls-vertical.php")
            )
            .then(function(partnerResponse, verticalResponse) {
                var response = partnerResponse[0];

                if (response.message !== undefined && response.message === "forbidden") {
                    return false;
                } else if (response.id !== undefined && response.email !== undefined) {
                    // Set the localstorage apiKey
                    if (token !== undefined) {
                        localStorage.apiKey = token;
                    }

                    _.each(verticalResponse[0], function(vert) {

                        registry.user.verticalCache.push({
                            'vertical': vert.str,
                            'premium': parseInt(vert.premium,10)

                        });
                    });
                    req.request("data/partner-banking.php")
                        .always(function(bankingResponse) {
                            if (bankingResponse.status === undefined || bankingResponse.status !== 404) {
                                if (bankingResponse.method == 3 || bankingResponse.method == 4) {
                                    $('.cryptoPaymentsWarning').show();
                                } else {
                                    $('.cryptoPaymentsWarning').hide();
                                }
                                registry.user.payment_method = bankingResponse.method;
                            }
                        });


                    // Set the user info in the registry
                    registry.user.id = response.id;
                    registry.user.email = response.email;
                    registry.user.name = response.name;
                    registry.user.nick = response.nick;
                    registry.user.postbackURL = response.postbackURL;
                    registry.user.push_subscription_postback_url = response.push_subscription_postback_url;
                    registry.user.nick_img = response.nick_img;
                    registry.user.nidAssignmentArr = response.nid_assignments;
                    registry.user.nidInfoArr = response.nid_info;
                    registry.user.adjustmentType = response.adjustment_type_str;
                    registry.user.walkytalkyUserHash = response.walkytalky_user_hash;
                    registry.user.private = response.private | 0;
                    registry.user.paymentNet = response.payment_net;
                    registry.user.paymentFrequency = response.payment_frequency;
                    registry.user.monetizer = response.monetizer;
                    registry.user.allow_premium = parseInt(response.allow_premium, 10);
                    registry.user.notification_top_offers = parseInt(response.notification_top_offers, 10);
                    registry.user.notification_messages = parseInt(response.notification_messages, 10);
                    registry.user.share = parseFloat(response.share);
                    registry.user.hide_ui_message = parseInt(response.hide_ui_message);
                    registry.user.notification_news = parseInt(response.notification_news);
                    registry.user.idVerified = parseInt(response.id_verified);
                    registry.user.bypassVerification = parseInt(response.bypass_verification);
                    registry.user.allowMessages = parseInt(response.allow_messages);
                    registry.user.allowDomains = parseInt(response.allow_domains);
                    registry.user.ioRequired = parseInt(response.io_required);
                    registry.user.ioTemplateName = response.io_template_name;
                    registry.user.ioNotification = response.io_notification;
                    registry.user.docusignIoId = response.docusign_io_id;
                    registry.user.unlockProfile = parseInt(response.unlock_profile) === 1;

                    // Check to see if a pid is specified
                    queryString = window.location.href.split("?");
                    if (queryString[1] !== undefined) {
                        queryString = queryString[1];

                        params = queryString.split("&");

                        _.each(params, function (param) {

                            if (param.substr(0,3) === "pid") {
                                registry.user.monetizer = 0;
                            }
                        });
                    }

                    registry.user.address_1 = response.address_1;
                    registry.user.address_2 = response.address_2;
                    registry.user.address_city = response.address_city;
                    registry.user.address_zip = response.address_zip;
                    registry.user.address_country = response.address_country;
                    registry.user.tel = response.tel;
                    registry.user.logo_img = response.logo_img;
                    registry.user.payment_request_date = response.payment_request_date;
                    registry.user.profileComplete = response.profileComplete;
                    registry.hasUserData.resolve();

                    self.trigger("authenticated");
                } else {
                    console.error("Error: Unexpected Response From API", response);
                }
            });

        },

        _checkDomains: function() {
            var self = this,
                req = new ApiRequestWorker();

            req.request("data/domains.php", {action: "list"})
                .done(function (data) {

                    _.each(data.domains, function (row) {
                        if (!parseInt(row.flagged)) {
                            return;
                        }

                        alert("Your domain " + row.domain +
                            " is reported as phishing/malware" +
                            " by Google Safe Browsing service.\n\n" +
                            "Please remove " + row.domain + " from our system" +
                            " (don't forget to remove A record!)" +
                            " and replace it with a clean one.\n\n" +
                            "This flag may be induced by receiving traffic" +
                            " from bad traffic sources.\n" +
                            "If you recently started getting traffic from a" +
                            " new traffic source, consider stopping using that" +
                            " source, otherwise new domain may be flagged again.");

                    });

                });
        }

    });

    return new UserWorker();

});

