/*jslint nomen:true, indent:4*/
/*global define, _, $, alert, console*/

define(["Backbone",
    "views/basic-report",
    "workers/apirequest",
    "moment-timezone",
    "registry",
    "views/graph",
    "views/daterange",
    "hsv-to-rgb",
    "views/pureSelect",
    "views/message",
    "views/offerDetailsModal",
    "templates/reportsTemplate.html",
    "templates/reportsRowTemplate.html"
    ], function (Backbone, BasicReport, ApiRequest, moment, registry, GraphView, DateRange, hsvToRgb, PureSelect, message, offerDetailsModal, reportsTemplate, rowTemplate) {

    var View = BasicReport.extend({

        // Constants
        TRENDS_MODE_SUMMARY: 0,
        TRENDS_MODE_TARGETED: 1,

        // Properties
        _subViews: [],
        data: null,
        trendData: null,
        trendParams: [],
        trendsMode: 0,
        exportName: "",

        $overlay: null,
        $tableWrapper: null,
        $table: null,
        $tbody: null,
        $tfootLoading: null,
        $header: null,
        $todayButton: null,
        $nameText: null,
        $countryButton: null,
        $countryOsButton: null,
        $countryOfferButton: null,
        $countryDeviceTypeButton: null,
        $countryOsDeviceDeviceTypeButton: null,
        $countryVerticalButton: null,
        $countryLinkButton: null,
        $countryLanguageButton: null,
        $carrierOfferButton: null,
        $ipRangeButton: null,
        $verticalLinkButton: null,
        $verticalOfferButton: null,
        $cityButton: null,
        $osButton: null,
        $versionButton: null,
        $deviceButton: null,
        $deviceTypeButton: null,
        $carrierButton: null,
        $daypartButton: null,
        $refererButton: null,
        $offerButton: null,
        $advertiserButton: null,
        $advertiserOfferButton: null,
        $carrierOfferAdvertiserSidButton: null,
        $wifiButton: null,
        $proxyTypeButton: null,
        $sidButton: null,
        $catButton: null,
        $tsButton: null,
        $kw4Button: null,
        $kw5Button: null,
        $langButton: null,
        $filteredButton: null,
        $cloakReasonButton: null,
        $flButton: null,
        $linkNameButton: null,
        $viewTableButton: null,
        $viewGraphButton: null,
        $viewTrendsButton: null,
        $headerButtonText: null,
        $exportButton: null,
        $fileInput: null,
        $graphWrapper: null,
        $graphButtonBar: null,
        $trendsButtonBar: null,
        $browserButton: null,
        $resolutionButton: null,



        // Graph Buttons
        $graphClicksButton: null,
        $graphLeadsButton: null,
        $graphCRButton: null,
        $graphRevenueButton: null,
        $graphEPCButton: null,
        $graphEcpmButton: null,
        $graphSortSelect: null,
        $graphLoader: null,

        // Trends Buttons
        $trendsClicksButton: null,
        $trendsLeadsButton: null,
        $trendsCRButton: null,
        $trendsRevenueButton: null,
        $trendsEPCButton: null,
        $trendsEcpmButton: null,
        $trendsLoader: null,

        $trendsWrapper: null,

        nid: null,
        rcid: null,
        name: null,
        currentExportUrl: null,

        runningRequests: [],

        sortBy: "clicks",
        sortByAlias: {
            ecpm: "epc"
        },
        keyword: "sid",
        keywordDesc: "Keyword 1",
        direction: false,
        trendsActiveMetric: "clicks",

        graphView: null,
        trendChartView: null,

        virtualMetrics: {
            'bots': 1,
            'cloaked': 1
        },

        offerDetailsFilters: ['offer','geo_offer','carrier_offer','vertical_offer','carrier_offer_network_id_sid'],

        // Events
        events: {
            "click .closeButton": "hide",
            "click .tableWrapper th": "clickTh",
            // "click tr": "clickTr",
            "click .trendsViewStyleButton": "clickTrendsMode",
            "click #sidKeywordButton": "clickSid",
            "click #catKeywordButton": "clickCat",
            "click #tsKeywordButton": "clickTs",
            "click #kw4KeywordButton": "clickKw4",
            "click #kw5KeywordButton": "clickKw5",
            "click #countryButton": "clickGeo",
            "click #countryOsButton": "clickGeoOs",
            "click #countryOfferButton": "clickGeoOffer",
            "click #countryDeviceTypeButton": "clickGeoDeviceType",
            "click #countryOsDeviceDeviceTypeButton":
            "clickCountryOsDeviceDeviceType",
            "click #countryVerticalButton": "clickCountryVertical",
            "click #countryLinkButton": "clickCountryLink",
            "click #countryLanguageButton": "clickCountryLanguage",
            "click #carrierOfferButton": "clickCarrierOffer",
            "click #ipRangeButton": "clickIpRange",
            "click #verticalLinkButton": "clickVerticalLink",
            "click #verticalOfferButton": "clickVerticalOffer",
            "click #cityButton": "clickCity",
            "click #osButton": "clickOS",
            "click #versionButton": "clickVersion",
            "click #refererButton": "clickReferer",
            "click #offerButton": "clickOffer",
            "click #advertiserButton": "clickAdvertiser",
            "click #advertiserOfferButton": "clickAdvertiserOffer",
            "click #carrierOfferAdvertiserSidButton":
            "clickCarrierOfferAdvertiserSid",
            "click #deviceButton": "clickDevice",
            "click #deviceTypeButton": "clickDeviceType",
            "click #carrierButton": "clickCarrier",
            "click #wifiButton": "clickConnection",
            "click #proxyTypeButton": "clickProxyType",
            "click #linkNameButton": "clickLinkName",
            "click #daypartButton": "clickDaypart",
            "click #browserButton": "clickBrowser",
            "click #resolutionButton": "clickResolution",
            "click #langButton": "clickLanguage",
            "click #filteredButton": "clickFiltered",
            "click #cloakReasonButton": "clickCloakReason",
            "click #flButton": "clickFl",
            "click #verticalButton": "clickVertical",
            "click #exportButton": "clickExport",
            "click .copy": "clickCopy",
            "change .trendsB": "clickAddToTrends",
            "click .graphButton": "clickGraphButton",
            "click .trendsButton": "clickTrendsButton",
            "click .tableView": "clickViewTable",
            "click .graphView": "clickViewGraph",
            "click .trendView": "clickViewTrends",
            'click .msgBtn': '_messageNetwork',
            'click .showOfferDetailsBtn': '_showOfferDetailsModal',
        },


        // Methods
        initialize: function () {
            var self = this,
                closeButton = null,
                inner = null,
                viewSelectContainer = null,
                buttonBar = null;

            this.setElement($(document.createElement("div")));
            this.$el.append(_.template(reportsTemplate)());
            $("#appContainer").append(this.$el);




            this.$overlay = this.$el.find('#reportsModal');
            inner = this.$el.find('.inner');
            closeButton = this.$el.find('.closeButton');

            this.$viewTableButton = this.$el.find('.tableView');
            this.$viewGraphButton = this.$el.find('.graphView');
            this.$viewTrendsButton = this.$el.find('.trendView');
            this.$nameText = this.$el.find('.panel-header h4 > span');
            this.$header = this.$el.find('.panel-header h4');

            buttonBar = this.$el.find('.buttonBar');
            this.$buttonBar = buttonBar;

            this.dateRange = new DateRange();
            this._subViews.push(this.dateRange);

            this.dateRange.on('change', this.changeDateRange, this);
            this.dateRange
                .render()
                .appendTo(this.$el.find('.selectDateRange'));

            // Keyword Dropdown
            this.$keywordsButton = this.$el.find("#keywordsButton");
            this.$sidButton = this.$el.find("#sidKeywordButton");
            this.$catButton = this.$el.find("#catKeywordButton");
            this.$tsButton = this.$el.find("#tsKeywordButton");
            this.$kw4Button = this.$el.find("#kw4KeywordButton");
            this.$kw5Button = this.$el.find("#kw5KeywordButton");

            // Geo Section
            this.$countryButton = this.$el.find("#countryButton");
            this.$countryOsButton = this.$el.find("#countryOsButton");
            this.$countryOfferButton = this.$el.find("#countryOfferButton");
            this.$countryDeviceTypeButton = this.$el.find("#countryDeviceTypeButton");
            this.$countryVerticalButton = this.$el.find("#countryVerticalButton");
            this.$countryLinkButton = this.$el.find("#countryLinkButton");
            this.$countryLanguageButton = this.$el.find("#countryLanguageButton");
            if (registry.user.getAccessLevel() === 'basic') {
                this.$countryVerticalButton.hide();
            }

            this.$cityButton = this.$el.find("#cityButton");

            // OS Dropdown
            this.$osButton = this.$el.find("#osButton");
            this.$versionButton = this.$el.find("#versionButton");
            this.$browserButton = this.$el.find("#browserButton");

            // Device Dropdown
            this.$deviceButton = this.$el.find("#deviceButton");
            this.$deviceTypeButton = this.$el.find("#deviceTypeButton");

            // CARRIER DROPDOWN
            this.$carrierButton = this.$el.find("#carrierButton");
            this.$carrierOfferButton = this.$el.find("#carrierOfferButton");
            this.$ipRangeButton = this.$el.find("#ipRangeButton");

            this.$wifiButton = this.$el.find("#wifiButton");
            this.$proxyTypeButton = this.$el.find("#proxyTypeButton");

            this.$langButton = this.$el.find("#langButton");

            this.$verticalButton = this.$el.find("#verticalButton");
            this.$verticalLinkButton = this.$el.find("#verticalLinkButton");
            this.$verticalOfferButton = this.$el.find("#verticalOfferButton");

            if (registry.user.getAccessLevel() === 'basic') {
                this.$verticalButton.hide();
                this.$verticalLinkButton.hide();
                this.$verticalOfferButton.hide();
            }

            this.$offerButton = this.$el.find("#offerButton");

            this.$advertiserButton = this.$el.find("#advertiserButton");
            this.$advertiserOfferButton = this.$el.find("#advertiserOfferButton");
            this.$carrierOfferAdvertiserSidButton =
                this.$el.find("#carrierOfferAdvertiserSidButton");

            // Link Dropdown
            this.$linkNameButton = this.$el.find("#linkNameButton");
            this.$flButton = this.$el.find("#flButton");
            this.$filteredButton = this.$el.find("#filteredButton");
            this.$cloakReasonButton = this.$el.find("#cloakReasonButton");

            this.$resolutionButton = this.$el.find("#resolutionButton");

            this.$daypartButton = this.$el.find("#daypartButton");

            this.$refererButton = this.$el.find("#refererButton");



            // Trend Chart
            this.$trendsWrapper =  this.$el.find('.trendsWrapper');
            this.$trendsButtonBar = this.$trendsWrapper.find(".trendsButtonBar");
            this.trendChartView = new GraphView({chartType: "linesets"});
            this.trendChartView.$el.appendTo(this.$trendsWrapper);
            this._subViews.push(this.trendChartView);

            // Graph
            this.$graphWrapper = this.$el.find('.graphWrapper');
            this.$graphButtonBar = this.$graphWrapper.find(".graphButtonBar");
            this.graphView = new GraphView({chartType:'bar'});
            this.graphView.$el.appendTo(this.$graphWrapper);
            this._subViews.push(this.graphView);

            // Graph Buttons
            this.$graphSortSelect = new PureSelect()
                .attr("id", "graphSortDirectionSelect")
                .addClass("graphButton")
                .updateData({
                    "1": "Sort Ascending",
                    "0": "Sort Descending"
                }, "0")
                .appendTo(this.$graphButtonBar.find('.graphSortWrapper'))
                .on("change", this.changeGraphDirection, this);
            this._subViews.push(this.$graphSortSelect);

            this.$graphLoader = this.$el.find(".graphLoading");


            // Trends Buttons
            this.$trendsViewSummaryButton = this.$trendsWrapper.find("#trendsButtonViewSummary").data("mode", this.TRENDS_MODE_SUMMARY);

            this.$trendsViewTargetedButton = this.$trendsWrapper.find("#trendsButtonViewTargeted").data("mode", this.TRENDS_MODE_TARGETED);


            this.$trendsLoader = this.$trendsWrapper.find(".trendsLoading");


            // Table Definition
            this.$tableWrapper = this.$el.find(".tableWrapper");
            this.$table = this.$tableWrapper.find('table');
            this.$tbody = this.$table.find('tbody');
            this.$tfootLoading = this.$table.find("tfoot");
            this.$exportButton = this.$tableWrapper.find("#exportButton");

            this.rowTemplate = _.template(rowTemplate);

            this.on("update:data", this.update, this);
            this.on("update:trendData", this.updateTrendChart, this);

            $(document).on("keydown", function (e) {
                self.keyPressed.call(self, e);
            });
        },

        getData: function () {
            var req = new ApiRequest(),
                self = this,
                args = "",
                promise = null,
                range = this.dateRange.get(),
                sortBy;

            this.showLoading();
            this.$tbody.empty();

            // Reset sortBy for virtual metrics
            if (this.virtualMetrics[this.sortBy]) {
                this.sortBy = 'clicks';
                this.direction = false;
            }

            sortBy = this.sortByAlias[this.sortBy] || this.sortBy;

            args = "&rcid=" + this.rcid +
                "&order=" + sortBy +
                "&direction=" + (this.direction ? "asc" : "desc") +
                "&keyword=" + this.keyword +
                "&start_ts=" + range.start +
                "&end_ts=" + range.end +
                "&tz=" + range.tz;

            // Abort all previous requests
            this.resetReport();

            promise = req.request("data/report.php?nid=" + self.nid + args)
                .done(function (data, afterRequest) {
                    var exportUrl;

                    if (data) {
                        exportUrl = afterRequest.getResponseHeader("X-Export-URL");
                        if (exportUrl && exportUrl.length > 0) {
                            self.currentExportUrl = exportUrl;
                            self.$exportButton.show();
                        }
                        else {
                            self.currentExportUrl = null;
                            self.$exportButton.hide();
                        }

                        self.data = data;

                        if(self.offerDetailsFilters.includes(self.keyword)) {
                            var offerIds = null;
                            switch(self.keyword) {
                                case 'offer':
                                    offerIds = data.map(row => row.keyword);
                                    break;
                                case 'geo_offer':
                                case 'carrier_offer':
                                case 'vertical_offer':
                                    offerIds = data.map(function(row) {
                                        var split = row.keyword.split(', ');
                                        return split[1];

                                    });
                                    break;
                                case 'carrier_offer_network_id_sid':
                                    offerIds = data.map(function(row) {
                                        var split = row.keyword.split('|');
                                        return split[1];
                                    });
                                    _.each(self.data, function(row,key) {
                                        var splitKeyword = row.keyword.split('|');
                                        self.data[key].keyword = splitKeyword[2]+'|'+splitKeyword[1]+'|'+splitKeyword[0]+'|'+splitKeyword[3];

                                    });

                                    break;
                            }

                            req.request('v3/offerDetails.php',{
                                'offer_hash': offerIds
                            })
                                .done(function(response) {

                                    self.offerDetails = _.indexBy(response,'offer_hash');

                                    var offerSplits = _.groupBy(response, 'offer_hash');
                                    _.each(self.offerDetails, (data, offer_hash) => {
                                        var paused = _.every(offerSplits[offer_hash], (split) => +split.paused === 1);
                                        self.offerDetails[offer_hash].paused =  paused ? 1 : 0;
                                    });

                                    self.trigger("update:data");

                                })


                        } else {
                            self.trigger("update:data");
                        }


                    }
                }).fail(function (jqXHR, status, error) {
                    var json = jqXHR.responseJSON;
                    var message = (json && typeof json.message === 'string') ? json.message : status + ': ' + error;

                    self.hideLoading();

                    if (status !== 'abort') {
                        alert(message);
                    }
                });

            this.runningRequests.push(promise.promise.request);
        },




        updateTrendData: function () {
            var req = new ApiRequest(),
                self = this,
                args = "",
                eventsArgs = "",
                range = this.dateRange.get(),
                summaryArgs = null,
                summaryPromise = null,
                eventsPromise = null,
                promise = null;



            if (_.size(this.trendParams) > 0) {

                 // Trends Data Request
                args += "&rcid=" + this.rcid;
                args += "&keyword=" + this.keyword;
                args += "&start_ts=" + range.start;
                args += "&end_ts=" + range.end;
                args += "&tz=" + range.tz;
                args += "&params=" + this.trendParams.join('\000');

                promise = req.request("data/partner-trends.php?nid=" + self.nid + args);
                this.runningRequests.push(promise.promise.request);
            } else {
                promise = true;
            }


            // Summary Request
            summaryArgs = "&rcid=" + this.rcid;
            summaryArgs += "&keyword=summary";
            summaryArgs += "&start_ts=" + range.start;
            summaryArgs += "&end_ts=" + range.end;
            summaryArgs += "&tz=" + range.tz;
            summaryArgs += "&params=";

            summaryPromise = req.request("data/partner-trends.php?nid=" + this.nid + summaryArgs);

            this.runningRequests.push(summaryPromise.promise.request);

            $.when(promise, summaryPromise).done(function (data, summaryData) {

                if (typeof data[0] === 'undefined') {
                    self.trendData = {};
                } else {
                    self.trendData = data[0];
                }

                self.summaryData = summaryData[0];
                self.trigger("update:trendData");

            });
        },


        updateTrendChart: function () {
            var self = this,
                dataSorted = {},
                keywordsUsed = [],
                labels = [],
                dataSets = {},
                actualSets = [],
                colors = null,
                i = null,
                rOperatingData = null;



            // Check for data
            if (this.trendsMode === this.TRENDS_MODE_SUMMARY) {
                if (_.size(this.summaryData) === 0) {
                    return false;
                } else {
                    rOperatingData = this.summaryData;
                }
            } else {
                if (_.size(this.trendData) === 0) {
                    this.trendsMode === this.TRENDS_MODE_SUMMARY;
                    this.$el.find('.trendsViewStyleButton').removeClass('active');
                    this.$el.find('#trendsButtonViewSummary').addClass('active');
                    rOperatingData = this.summaryData;
                } else {
                    rOperatingData = this.trendData;
                }
            }

            // Initial Sort
            _.each(rOperatingData, function (row) {
                if (dataSorted[row.time_unit] === undefined) {
                    dataSorted[row.time_unit] = {};
                }

                if (_.indexOf(keywordsUsed, row.keyword) === -1) {
                    keywordsUsed.push(row.keyword);
                    dataSets[row.keyword] = [];
                }

                row.epc = parseFloat(row.revenue / row.clicks).toFixed(5);
                row.epc = isFinite(row.epc) ? row.epc : 0.00000;

                row.cr = parseFloat((row.leads / row.clicks) * 100).toFixed(2);
                row.cr = isFinite(row.cr) ? row.cr : 0.00;

                row.ecpm = (row.revenue * 1000 / row.clicks).toFixed(2);

                dataSorted[row.time_unit][row.keyword] = row;
            });

            // Data Sort
            _.each(dataSorted, function (row, index) {
                labels.push(index);

                _.each(keywordsUsed, function (keywordCheck) {
                    if (row[keywordCheck] === undefined) {
                        dataSets[keywordCheck].push(0);
                    } else {
                        dataSets[keywordCheck].push(row[keywordCheck][self.trendsActiveMetric]);
                    }
                });
            });

            i = 0;

            if (_.size(dataSets) > 1) {
                colors = self.distinctColors(_.size(dataSets));
            } else {
                colors = [[71,144,20]];
            }

            // KFX Let's just check that everything is the right length
            _.each(dataSets, function (set, index) {
                if (_.size(set) !== _.size(labels)) {
                    console.error("PROBLEM: dataset " + index + "has an invalid length.", _.size(set), _.size(labels));
                }

                actualSets.push({
                    label: index,
                    backgroundColor: "rgba(" + colors[i][0] + "," + colors[i][1] + "," + colors[i][2] + ",0.05)",
                    borderColor: "rgba(" + colors[i][0] + "," + colors[i][1] + "," + colors[i][2] + ",1)",
                    pointBackgroundColor: "rgba(" + colors[i][0] + "," + colors[i][1] + "," + colors[i][2] + ",1)",
                    pointBorderColor: "#fff",
                    pointHoverBackgroundColor: "#fff",
                    pointHoverBorderColor: "rgba(" + colors[i][0] + "," + colors[i][1] + "," + colors[i][2] + ",1)",
                    data: set
                });

                i+=1;
            });


            this.trendChartView.renderLineWithDatasets(labels, actualSets);

        },



        resetReport: function() {
            _.each(this.runningRequests, function (request) {
                request.abort();
            });
        },



        reOrderData: function(data, direction, slug) {
            var first = null,
                second = null,
                firstVal = null,
                secondVal = null;

            data = data.sort(function (a,b) {
                if (!direction) {
                    first = b;
                    second = a;
                } else {
                    first = a;
                    second = b;
                }

                if (second[slug] === undefined || second[slug] === undefined) {
                    console.error("Sort slug is not a property of the data row", slug);
                    return 0;
                } else {

                    firstVal = isNaN(parseFloat(first[slug])) ? first[slug] : parseFloat(first[slug]);
                    secondVal = isNaN(parseFloat(second[slug])) ? second[slug] : parseFloat(second[slug]);

                    if (firstVal > secondVal) {
                        return 1;
                    } else if (firstVal === secondVal) {
                        return 0;
                    } else {
                        return -1;
                    }
                }
            });
        },


        update: function () {
            var self = this,
                docFragment = $(document.createDocumentFragment()),
                rCr = null,
                rCloaked = null,
                rBots = null,
                rEpc = null,
                rClass = "",
                rKeyword = null,
                topTenX = [],
                topTenY = [];

            this.hideLoading();
            $("#buttonTypeText").text(this.keywordDesc);
            this.$graphSortSelect.val(this.direction ? "1" : "0");

            if (this.data.length > 0) {
                _.each(this.data, function (row, index) {

                    if (index > 499) {
                        return false;
                    }

                    rCr = isFinite(parseFloat(row.cr)) ? parseFloat(row.cr).toFixed(2) : row.cr;
                    rEpc = isFinite(parseFloat(row.epc)) ? parseFloat(row.epc).toFixed(5) : row.epc;

                    row.cloaked = (parseInt(row.filtered_clicks, 10) / parseInt(row.clicks, 10)) * 100;
                    rCloaked = isFinite(row.cloaked) ? row.cloaked.toFixed(1) : 0;

                    row.bots = parseInt(row.bot_clicks) * 100 / parseInt(row.clicks);
                    rBots = isFinite(row.bots) ? row.bots.toFixed(1) : 0;

                    row.ecpm = (row.epc * 1000).toFixed(2);

                    rClass = self.classifyRow(row);

                    row.offerHashId = null;
                    row.offerDetails = null;
                    if(self.offerDetailsFilters.includes(self.keyword)) {
                        switch (self.keyword) {
                            case 'offer':
                                row.offerDetails = self.offerDetails[row.keyword];
                                row.offerHashId = row.keyword;

                                break;
                            case 'geo_offer':
                            case 'carrier_offer':
                            case 'vertical_offer':
                                row.offerHashId = row.keyword.split(', ')[1];
                                row.offerDetails = self.offerDetails[row.offerHashId];
                                break;
                            case 'carrier_offer_network_id_sid':
                                var splitKeyword = row.keyword.split('|');
                                row.offerHashId = splitKeyword[1];
                                row.offerDetails = self.offerDetails[row.offerHashId];

                                break;

                        }
                    }




                    try {
                        rKeyword = decodeURIComponent(row.keyword);
                    }
                    catch(URIError) {
                        console.log("ERROR WITH ", row.keyword);
                        rKeyword = row.keyword.replace(/%20/g, " ");
                    }

                    if (index < 24) {
                        topTenX.push(rKeyword);

                        switch (self.sortBy) {
                            case "clicks":
                                topTenY.push(row.clicks);
                                break;

                            case "leads":
                                topTenY.push(row.leads);
                                break;

                            case "cr":
                                topTenY.push(rCr);
                                break;

                            case "revenue":
                                topTenY.push(parseFloat(row.revenue).toFixed(2));
                                break;

                            case "epc":
                                topTenY.push(rEpc);
                                break;

                            case "ecpm":
                                topTenY.push(row.ecpm);
                                break;

                            case "cloaked":
                                topTenY.push(rCloaked);
                                break;

                            case "bots":
                                topTenY.push(rBots);
                                break;
                        }
                    }


                    docFragment.append(self.rowTemplate({
                        keywordFull: encodeURIComponent(rKeyword),
                        clicks: row.clicks,
                        cloaked: rCloaked + "%",
                        bots: rBots + "%",
                        cr: rCr,
                        ctr: parseFloat(row.ctr).toFixed(2) + "%",
                        date: moment.unix(row.data_start).format("ddd, hA"),
                        leads: row.leads,
                        ecpm: row.ecpm,
                        epc: rEpc,
                        className: rClass,
                        revenue: "$" + parseFloat(row.revenue).toFixed(2),
                        keyword: rKeyword,
                        reportKeyword: self.keyword,
                        offerDetails: row.offerDetails,
                        offerHashId: row.offerHashId


                    }));
                });


                self.$tbody.append(docFragment);

            } else {
                self.$tbody.append($(document.createElement("tr"))
                    .html("<td class=\"noData text-muted\" colspan=\"100%\" style=\"text-align:center;\" width=\'150\'>No clicks to show for this date range.</td>")
                );
            }

            this.graphView.render(topTenX, topTenY);

        },


        showLoading: function () {
            this.$graphLoader.show();
            this.$tfootLoading.show();
            this.$exportButton.hide();
        },

        hideLoading: function() {
            this.$graphLoader.hide();
            this.$tfootLoading.hide();
            this.$exportButton.show();
        },

        setTarget: function (options) {
            this.nid = options.nid;
            this.rcid = options.rcid;
            this.name = options.name;
            this.trendParams = [];
            this.exportName = this.name;
        },

        show: function () {
            this.$nameText.text("\"" + this.name + "\"");

            $('body, html').addClass('overflow-hidden');
            this.$overlay.fadeIn('fast');
            this.getData();
            this.updateTrendData();
        },

        hide: function () {
            var self = this;
            self.$overlay.fadeOut('fast', function() {
                $('body, html').removeClass('overflow-hidden');
                self.$tbody.empty();

            });
        },

        clickTh: function (e) {
            var slug = $(e.currentTarget).data("slug");
            if (slug !== "none") {
                if (this.sortBy === slug) {
                    this.direction = !this.direction;
                } else {
                    this.direction = false;
                }

                this.sortBy = slug;
                this.sortByChanged();
            }
        },

        clickAddToTrends: function (e) {
            var keyword = $(e.currentTarget).closest("tr").data("key"),
                indexOfKeyword = null;

            this.trendsMode = this.TRENDS_MODE_TARGETED;
            this.$el.find(".trendsViewStyleButton").removeClass("active");
            this.$trendsViewTargetedButton.addClass("active");

            if (keyword !== undefined && keyword !== "none") {
                indexOfKeyword = _.indexOf(this.trendParams, keyword);
                if (indexOfKeyword > -1) {
                    this.trendParams.splice(indexOfKeyword, 1);
                    $(e.currentTarget).removeClass("active");
                } else {
                    this.trendParams.push(keyword);
                    $(e.currentTarget).addClass("active");
                }

                this.updateTrendData();
            }

        },

        handleDropdownActiveState: function (e) {
            var $target = $(e.target),
                $parent = $target.closest(".dropDownLabel");

            this.$el.find(".navbar-nav a").removeClass("active");
            this.$el.find(".dropDownLabel").removeClass("active");

            if ($parent.length > 0) {
                $parent.addClass("active");
            }
        },

        clickSid: function (e) {
            this.handleDropdownActiveState(e);
            this.keyword = "sid";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$sidButton.addClass("active");
            this.keywordDesc = "Keyword 1";
            this.exportName = this.name + "_Keyword-1";
            this.tabChanged();
            this.getData();
        },

        clickCat: function (e) {
            this.handleDropdownActiveState(e);
            this.keyword = "cat";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$catButton.addClass("active");
            this.keywordDesc = "Keyword 2";
            this.exportName = this.name + "_Keyword-2";
            this.tabChanged();
            this.getData();
        },

        clickTs: function (e) {
            this.handleDropdownActiveState(e);
            this.keyword = "ts";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$tsButton.addClass("active");
            this.keywordDesc = "Keyword 3";
            this.exportName = this.name + "_Keyword-3";
            this.tabChanged();
            this.getData();
        },

        clickKw4: function (e) {
            this.handleDropdownActiveState(e);
            this.keyword = "kw4";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$kw4Button.addClass("active");
            this.keywordDesc = "Keyword 4";
            this.exportName = this.name + "_Keyword-4";
            this.tabChanged();
            this.getData();
        },

        clickKw5: function (e) {
            this.handleDropdownActiveState(e);
            this.keyword = "kw5";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$kw5Button.addClass("active");
            this.keywordDesc = "Keyword 5";
            this.exportName = this.name + "_Keyword-5";
            this.tabChanged();
            this.getData();
        },

        clickGeo: function (e) {
            this.handleDropdownActiveState(e);
            this.keyword = "geo";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$countryButton.addClass("active");
            this.keywordDesc = "Country";
            this.exportName = this.name + "_Country";
            this.tabChanged();
            this.getData();
        },

        clickGeoOs: function (event) {
            this.handleDropdownActiveState(event);
            event.stopPropagation();

            this.keyword = "geo_os";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$countryOsButton.addClass("active");
            this.keywordDesc = "Country, OS";
            this.exportName = this.name + "_Country_OS";
            this.tabChanged();
            this.getData();
        },

        clickGeoOffer: function (event) {
            this.handleDropdownActiveState(event);
            event.stopPropagation();

            this.keyword = "geo_offer";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$countryOfferButton.addClass("active");
            this.keywordDesc = "Country, OFFER";
            this.exportName = this.name + "_Country_Offer";
            this.tabChanged();
            this.getData();
        },

        clickGeoDeviceType: function (event) {
            this.handleDropdownActiveState(event);
            event.stopPropagation();

            this.keyword = "geo_device_type";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$countryDeviceTypeButton.addClass("active");
            this.keywordDesc = "Country, Device Type";
            this.exportName = this.name + "_Country_DeviceType";
            this.tabChanged();
            this.getData();
        },

        clickCountryOsDeviceDeviceType: function (event) {
            this.handleDropdownActiveState(event);
            event.stopPropagation();

            this.keyword = "geo_os_device_device_type";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$countryOsDeviceDeviceTypeButton.addClass("active");
            this.keywordDesc = "Country, OS > Device > Device Type";
            this.exportName = this.name + "_Country_OS_Device_DeviceType";
            this.tabChanged();
            this.getData();
        },

        clickCountryVertical: function (event) {
            this.handleDropdownActiveState(event);
            event.stopPropagation();

            this.keyword = "geo_vertical";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$countryVerticalButton.addClass("active");
            this.keywordDesc = "Country, Vertical";
            this.exportName = this.name + "_Country_Vertical";
            this.tabChanged();
            this.getData();
        },

        clickCountryLink: function (event) {
            this.handleDropdownActiveState(event);
            event.stopPropagation();

            this.keyword = "geo_link";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$countryLinkButton.addClass("active");
            this.keywordDesc = "Country, Link";
            this.exportName = this.name + "_Country_Link";
            this.tabChanged();
            this.getData();
        },

        clickCountryLanguage: function (event) {
            this.handleDropdownActiveState(event);
            event.stopPropagation();

            this.keyword = "geo_language";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$countryLanguageButton.addClass("active");
            this.keywordDesc = "Country, Language";
            this.exportName = this.name + "_Country_Language";
            this.tabChanged();
            this.getData();
        },

        clickCarrierOffer: function (event) {
            this.handleDropdownActiveState(event);
            event.stopPropagation();

            this.keyword = "carrier_offer";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$carrierOfferButton.addClass("active");
            this.keywordDesc = "Carrier, Offer";
            this.exportName = this.name + "_Carrier_Offer";
            this.tabChanged();
            this.getData();
        },

        clickCarrierOfferAdvertiserSid: function (event) {
            this.handleDropdownActiveState(event);
            event.stopPropagation();

            this.keyword = "carrier_offer_network_id_sid";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$carrierOfferButton.addClass("active");
            this.keywordDesc = "Advertiser, Offer, Carrier, Kw1";
            this.exportName = this.name + "_Carrier_Offer_Advertiser_Kw1";
            this.tabChanged();
            this.getData();
        },

        clickIpRange: function (event) {
            this.handleDropdownActiveState(event);
            event.stopPropagation();

            this.keyword = "ip_range";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$ipRangeButton.addClass("active");
            this.keywordDesc = "IP Range";
            this.exportName = this.name + "_IP_Range";
            this.tabChanged();
            this.getData();
        },

        clickVerticalLink: function (event) {
            this.handleDropdownActiveState(event);
            event.stopPropagation();

            this.keyword = "vertical_link";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$verticalLinkButton.addClass("active");
            this.keywordDesc = "Vertical, Link";
            this.exportName = this.name + "_Vertical_Link";
            this.tabChanged();
            this.getData();
        },

        clickVerticalOffer: function (event) {
            this.handleDropdownActiveState(event);
            event.stopPropagation();

            this.keyword = "vertical_offer";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$verticalOfferButton.addClass("active");
            this.keywordDesc = "Vertical, Offer";
            this.exportName = this.name + "_Vertical_Offer";
            this.tabChanged();
            this.getData();
        },

        clickCity: function (event) {
            this.handleDropdownActiveState(event);
            this.keyword = "city";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$cityButton.addClass("active");
            this.keywordDesc = "City";
            this.exportName = this.name + "_City";
            this.tabChanged();
            this.getData();
        },

        clickOS: function (e) {
            this.handleDropdownActiveState(e);
            this.keyword = "os";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$osButton.addClass("active");
            this.keywordDesc = "Operating System";
            this.exportName = this.name + "_OS";
            this.tabChanged();
            this.getData();
        },

        clickVersion: function (event) {
            this.handleDropdownActiveState(event);
            event.stopPropagation();

            this.keyword = "os_ver";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$versionButton.addClass("active");
            this.keywordDesc = "Operating System Version";
            this.exportName = this.name + "_OS-Ver";
            this.tabChanged();
            this.getData();
        },

        clickDevice: function (e) {
            this.handleDropdownActiveState(e);
            this.keyword = "device";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$deviceButton.addClass("active");
            this.keywordDesc = "Device";
            this.exportName = this.name + "_Device";
            this.tabChanged();
            this.getData();
        },

        clickDeviceType: function (event) {
            this.handleDropdownActiveState(event);
            event.stopPropagation();

            this.keyword = "device_type";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$deviceTypeButton.addClass("active");
            this.keywordDesc = "Device Type";
            this.exportName = this.name + "_DeviceType";
            this.tabChanged();
            this.getData();
        },

        clickCarrier: function (e) {
            this.handleDropdownActiveState(e);
            this.keyword = "carrier";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$carrierButton.addClass("active");
            this.keywordDesc = "Carrier";
            this.exportName = this.name + "_Carrier";
            this.tabChanged();
            this.getData();
        },

        clickConnection: function (e) {
            this.handleDropdownActiveState(e);
            this.keyword = "connection";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$wifiButton.addClass("active");
            this.keywordDesc = "Connection";
            this.exportName = this.name + "_Connection";
            this.tabChanged();
            this.getData();
        },

        clickProxyType: function (e) {
            this.handleDropdownActiveState(e);
            e.stopPropagation();

            this.keyword = "proxy_type";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$proxyTypeButton.addClass("active");
            this.keywordDesc = "Proxy Type";
            this.exportName = this.name + "_ProxyType";
            this.tabChanged();
            this.getData();
        },

        clickReferer: function (e) {
            this.handleDropdownActiveState(e);
            this.keyword = "referer";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$refererButton.addClass("active");
            this.keywordDesc = "Referer";
            this.exportName = this.name + "_Referer";
            this.tabChanged();
            this.getData();
        },

        clickOffer: function (e) {
            this.handleDropdownActiveState(e);
            this.keyword = "offer";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$offerButton.addClass("active");
            this.keywordDesc = "Offer";
            this.exportName = this.name + "_Offer";
            this.tabChanged();
            this.getData();
        },

        clickAdvertiser: function (e) {
            this.handleDropdownActiveState(e);
            this.keyword = "network_id";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$advertiserButton.addClass("active");
            this.keywordDesc = "Advertiser";
            this.exportName = this.name + "_Advertiser";
            this.tabChanged();
            this.getData();
        },

        clickAdvertiserOffer: function (e) {
            this.handleDropdownActiveState(e);
            this.keyword = "network_id_offer";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$advertiserButton.addClass("active");
            this.keywordDesc = "Advertiser, Offer";
            this.exportName = this.name + "_Advertiser_Offer";
            this.tabChanged();
            this.getData();
        },

        clickLinkName: function (e) {
            this.handleDropdownActiveState(e);
            this.keyword = "link";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$linkNameButton.addClass("active");
            this.keywordDesc = "Link Name";
            this.exportName = this.name + "_LinkName";
            this.tabChanged();
            this.getData();
        },

        clickDaypart: function(e) {
            this.handleDropdownActiveState(e);
            this.keyword = "hour";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$daypartButton.addClass("active");
            this.keywordDesc = "Day Parting";
            this.exportName = this.name + "_DayPart";
            this.tabChanged();
            this.getData();
        },

        clickBrowser: function(e) {
            this.handleDropdownActiveState(e);
            this.keyword = "browser";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$browserButton.addClass("active");
            this.keywordDesc = "Browser";
            this.exportName = this.name + "_Browser";
            this.tabChanged();
            this.getData();
        },


        clickResolution: function(e) {
            this.handleDropdownActiveState(e);
            this.keyword = "screen_res";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$resolutionButton.addClass("active");
            this.keywordDesc = "Screen Resolution";
            this.exportName = this.name + "_ScreenRes";
            this.tabChanged();
            this.getData();
        },


        clickLanguage: function(e) {
            this.handleDropdownActiveState(e);
            this.keyword = "language";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$langButton.addClass("active");
            this.keywordDesc = "Language";
            this.exportName = this.name + "_Language";
            this.tabChanged();
            this.getData();
        },


        clickFiltered: function(event) {
            this.handleDropdownActiveState(event);
            event.stopPropagation();

            this.keyword = "filtered";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$filteredButton.addClass("active");
            this.keywordDesc = "Filtered";
            this.exportName = this.name + "_Filtered";
            this.tabChanged();
            this.getData();
        },

        clickCloakReason: function(event) {
            this.handleDropdownActiveState(event);
            event.stopPropagation();

            this.keyword = "cloak_reason";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$cloakReasonButton.addClass("active");
            this.keywordDesc = "Cloak Breakdown";
            this.exportName = this.name + "_CloakReason";
            this.tabChanged();
            this.getData();
        },

        clickFl: function(event) {
            this.handleDropdownActiveState(event);
            event.stopPropagation();

            this.keyword = "fl";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$flButton.addClass("active");
            this.keywordDesc = "Click Stage";
            this.exportName = this.name + "_Click_Phase";
            this.tabChanged();
            this.getData();
        },

        clickVertical: function(e) {
            this.handleDropdownActiveState(e);
            this.keyword = "actual_vertical";
            this.$el.find(".textButton, .dropDowntextButton").removeClass("activeKeyword");
            this.$verticalButton.addClass("active");
            this.keywordDesc = "Vertical";
            this.exportName = this.name + "_Veritcal";
            this.tabChanged();
            this.getData();
        },


        tabChanged: function() {
            if (this.trendsMode === this.TRENDS_MODE_TARGETED) {
                this.trendParams = [];
                this.trendData = {};
            }
        },

        changeDateRange: function() {
            this.getData();
            this.updateTrendData();
        },


        clickCopy: function (e) {
            var clipboard = registry.gui.Clipboard.get(),
                theText = $(e.currentTarget).parent().siblings().first().data("actual");

            clipboard.set( "" + theText, "text");
            $(e.currentTarget).addClass("isCopy");
            alert("\"" + theText + "\" copied to clipboard");
        },


        clickExport: function () {
            this.exportToCSV();
        },

        exportToCSV: function () {
            var downloadLink = null;

            downloadLink = document.createElement("a");
            downloadLink.setAttribute("href", this.currentExportUrl);
            downloadLink.setAttribute("download", "monetizer-leagues.csv");
            downloadLink.style.display = "none";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        },


        keyPressed: function (e) {
            if (e.which === 27) {
                this.hide();
            }
        },

        sortByChanged: function () {
            var self = this,
                $rEl,
                $graphButtons = this.$el.find("button.graphButton");

            $graphButtons.removeClass("active");
            $graphButtons.each(function (index, el) {
                $rEl = $(el);
                if ($rEl.data("label") === self.sortBy) {
                    $rEl.addClass("active");
                }
            });

            this.$tbody.empty();
            this.reOrderData(self.data, self.direction, self.sortBy);
            this.update();
        },

        clickGraphButton: function (e) {
            var $el = $(e.currentTarget);

            if ($el.data("label")) {
                this.sortBy = $el.data("label");
                this.sortByChanged();
            }
        },


        clickTrendsButton: function(e) {
            var $el = $(e.currentTarget);

            if ($el.data("label")) {
                this.$el.find(".trendsButton").removeClass("active");
                $el.addClass("active");

                this.trendsActiveMetric = $el.data("label");
                this.updateTrendChart();
            } else {
                // Do the other thing
            }
        },


        clickViewTable: function() {
            this.$viewTrendsButton.removeClass("active");
            this.$viewGraphButton.removeClass("active");
            this.$trendsWrapper.hide();
            this.$graphWrapper.hide();

            this.$buttonBar.show();

            this.$tableWrapper.show();
            this.$viewTableButton.addClass("active");
        },

        clickViewGraph: function() {
            this.$viewTableButton.removeClass("active");
            this.$viewTrendsButton.removeClass("active");
            this.$tableWrapper.hide();
            this.$trendsWrapper.hide();

            // No graph for first column sorting
            if (this.sortBy === "keyword") {
                this.sortBy = "clicks";
                this.sortByChanged();
            }

            this.$buttonBar.show();

            this.$graphWrapper.show();
            this.$viewGraphButton.addClass("active");
        },

        clickViewTrends: function() {

            this.$viewTableButton.removeClass("active");
            this.$viewGraphButton.removeClass("active");
            this.$tableWrapper.hide();
            this.$graphWrapper.hide();

            this.$buttonBar.hide();

            this.$trendsWrapper.show();
            this.$viewTrendsButton.addClass("active");
        },

        clickTrendsMode: function (e) {
            var $el = $(e.currentTarget);

            if (parseInt($el.data("mode"), 10) !== this.trendsMode) {
                $(".trendsViewStyleButton").removeClass("active");
                $el.addClass("active");
                this.trendsMode = parseInt($el.data("mode"), 10);
                this.updateTrendChart();
            }
        },

        changeGraphDirection: function() {
            this.direction = !this.direction;
            this.$tbody.empty();
            this.reOrderData(this.data, this.direction, this.sortBy);
            this.update();
        },


        distinctColors: function (count) {
            var colors = [];

            for(var hue = 0; hue < 360; hue += 360 / count) {
                colors.push(hsvToRgb(hue, 73, 73));
            }

            return colors;
        },

        _messageNetwork: function(ev) {
            new message({
                'action': 'new',
                'network_id': ev.currentTarget.dataset.id
            });

        },

        _showOfferDetailsModal: function(ev) {

            new offerDetailsModal({
                offerHashId: ev.currentTarget.dataset.id
            })

        }

    });

    return new View();
});

